import React, { useState, useEffect } from "react";
import {
	Button,
	Divider,
  } from "@material-ui/core";
  
  import {
	GetApp,
  } from "@material-ui/icons";

import api from "../../services/api";

const ModalFileCors = ({ url, className }) => {
	const [blobUrl, setBlobUrl] = useState("");

	useEffect(() => {
		url = encodeURI(url)
		if(url.includes('http://localhost:')){
			url = url.replace(/(:\d+)(\/)/, '$2');
		}

		if (!url) return;
			api.get(url, {
				responseType: "blob",
			}).then(({data, headers})=>{
				const url = window.URL.createObjectURL(
					new Blob([data], { type: headers["content-type"] })
				);
				setBlobUrl(url);
			}).catch((error) => {
				console.error(error);
			})
			
			
	}, [url]);

	return (
		<>
		<div className={className}>
		  <Button
			startIcon={<GetApp />}
			color="primary"
			variant="outlined"
			target="_blank"
			href={blobUrl}
		  >
			Descargar
		  </Button>
		</div>
		<Divider />
	  </>
	);
};

export default ModalFileCors;
