import React, { useState, useEffect, useReducer, useContext, useRef } from "react";

import { toast, Bounce } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ImportedContactModal from "../../components/ImportedContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import NewTicketModal from "../../components/NewTicketModal";
import { socketConnection } from "../../services/socket";
import ExternalDBModal from "../ExternalDBModal";

const reducer = (state, action) => {
  if (action.type === "LOAD_DB") {
    const elements = action.payload;
    const newElements = [];

    elements.forEach((element) => {
      const elementIndex = state.findIndex((c) => c.id === element.id);
      if (elementIndex !== -1) {
        state[elementIndex] = element;
      } else {
        newElements.push(element);
      }
    });

    return [...state, ...newElements];
  }

  if (action.type === "UPDATE_DB") {
    const element = action.payload;
    const elementIndex = state.findIndex((c) => c.id === element.id);

    if (elementIndex !== -1) {
      state[elementIndex] = element;
      return [...state];
    } else {
      return [element, ...state];
    }
  }

  if (action.type === "DELETE_DB") {
    const elementId = action.payload;

    const elementIndex = state.findIndex((c) => c.id === elementId);
    if (elementIndex !== -1) {
      state.splice(elementIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const ExternalDBManager = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [elements, dispatch] = useReducer(reducer, []);
  const [hasMore, setHasMore] = useState(false);

  const [selectedElement, setSelectedElement] = useState(null);
  const [DBModalOpen, setDBModalOpen] = useState(false);
  const [DBModalAction, setDBModalAction] = useState(()=>{});
  const [DBModalTest, setDBModalTest] = useState(()=>{});
  const [DBModalTitle, setDBModalTitle] = useState("");
  
  const [swalOpen, setSwalOpen] = useState(false);
  const [swalTitle, setSwalTitle] = useState("");
  const [swalText, setSwalText] = useState("");
  const [swalAction, setSwalAction] = useState(()=>{});

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(async () => {
        try {
          const { data } = await api.get(`/external-db/${pageNumber}`);
          dispatch({ type: "LOAD_DB", payload: data.records });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err.response?.data.message);
        }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [pageNumber]);

  //MARK: Socket
  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });
    socket.on(`company-${companyId}-externaldb`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_DB", payload: data.record });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_DB", payload: + data.elementId });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);


  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };
  
  const handleTest = async (element) => {
    try {
      const valid = await api.post("/external-db/test",element);
      toast.success(valid.data.message)
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  

  const handleDeleteElement = async (elementId) => {
    try{
      const valid = await api.delete(`/external-db/${elementId}`)
      toast.success(valid.data.message)
    }catch(e){
      toastError(e.response?.data.message);
    }
  }

  const handleAlertDeleteElement = (elementId) => {
    setSwalTitle("Eliminar elemento");
    setSwalText("seguro de eliminar este elemento?");
    setSwalAction(()=>()=>handleDeleteElement(elementId));
    setSwalOpen(true);
  }

  const handleCreateElement = async (element) => {
    try{
      const result = await api.post("/external-db",element);
      toast.success(result.data.message)
      setSelectedElement({});
      setDBModalTitle("");
      setDBModalAction(()=>()=>{});
      setDBModalOpen(false);
    }catch(e){
      toastError(e)
    }
  }

  const handleOpenCreateModal = () => {
    setSelectedElement({});
    setDBModalTitle("Agregar DB Externa");
    setDBModalAction(()=>handleCreateElement);
    setDBModalTest(()=>handleTest);
    setDBModalOpen(true);
  }

  const handleEditElement = async (element) => {
    try{
      const result = await api.put("/external-db",element);
      toast.success(result.data.message)
      setSelectedElement({});
      setDBModalTitle("");
      setDBModalAction(()=>()=>{});
      setDBModalOpen(false);
    }catch(e){
      toastError(e)
    }
  }
  const handleOpenEditModal = (element) => {
    setSelectedElement(element);
    setDBModalTitle("Editar DB Externa");
    setDBModalAction(()=>handleEditElement);
    setDBModalTest(()=>handleTest);
    setDBModalOpen(true);
  }


  return (
    <MainContainer className={classes.mainContainer}>
      <ConfirmationModal
        title={swalTitle}
        open={swalOpen}
        onClose={setSwalOpen}
        onConfirm={swalAction}
      >
        {swalText}
      </ConfirmationModal>
      <ExternalDBModal
        title={DBModalTitle}
        open={DBModalOpen}
        onClose={()=>{setDBModalOpen(false)}}
        onSend={DBModalAction}
        onTest={DBModalTest}
        initialElement={selectedElement}
      />
      <MainHeader>
        <Title>Bases de datos externas</Title>
        <MainHeaderButtonsWrapper>
        <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCreateModal}
          >
            Añadir
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre DB</TableCell>
              <TableCell>Nombre de Usuario</TableCell>
              <TableCell>Host</TableCell>
              <TableCell>Puerto</TableCell>
              <TableCell>Dialecto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {elements.map((element) => (
                <TableRow key={element.id}>
                  <TableCell>{element.id}</TableCell>
                  <TableCell>{element.name}</TableCell>
                  <TableCell>{element.username}</TableCell>
                  <TableCell>{element.host}</TableCell>
                  <TableCell>{element.port}</TableCell>
                  <TableCell>{element.dialect}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={()=>handleAlertDeleteElement(element.id)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={()=>handleOpenEditModal(element)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={6} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default ExternalDBManager;
