import React, { useContext, useEffect, useReducer, useState } from "react";

import { toast } from "react-toastify";

import api from "../../services/api";
import { socketConnection } from "../../services/socket";

import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { grey, red, indigo, cyan } from "@material-ui/core/colors";

import PersonIcon from "@material-ui/icons/Person"
import EditIcon from "@material-ui/icons/EditOutlined"
import DeleteIcon from "@material-ui/icons/DeleteOutline"
import SendIcon from "@material-ui/icons/SendOutlined"
import CloseIcon from "@material-ui/icons/Close"

import Title from "../../components/Title";
import MainHeader from "../../components/MainHeader";
import MainContainer from "../../components/MainContainer";
import { Box, CircularProgress, IconButton, MenuItem, TableContainer, TextField } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import Select from 'react-select';

const useStyles = makeStyles((theme) => ({
	table: {
		maxWidth: '300px'
	},
	mainPaper: {
		flex: 1,
		padding: 0,
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	tableCell: {
		backgroundColor: "white",
		borderBottomStyle: "solid",
		borderBottomColor: grey[400],
		borderBottomWidth: "0.8px",
		fontSize: '1rem',
		fontWeight: 'lighter',
		fontFamily: 'Monospace',
		display: "tableRowGroup",
		alignItems: "center",
		maxWidth: "300px",
		padding: "0px 8px"
	},
	bodyCell: {
		backgroundColor: grey[100],
		border: "none",
		fontSize: '0.8rem',
		fontWeight: 'normal',
		fontFamily: 'Monospace',
		padding: "8px 16px",
		'&:hover': {
			backgroundColor: grey[400],
		},
	},
	addCell: {
		backgroundColor: cyan[100],
		border: "none",
		fontSize: '0.8rem',
		fontWeight: 'normal',
		fontFamily: 'Monospace',
		padding: "8px 16px",
		'&:hover': {
			backgroundColor: cyan[300],
		},
	},
	headerCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	headerEditButton: {
		backgroundColor: indigo[100],
		color: indigo[900],
		transition: 'background-color 0.3s',
		padding: "8px",
		marginRight: "8px",
		'&:hover': {
			backgroundColor: indigo[300]
		}
	},
	bodyEditButton: {
		backgroundColor: indigo[100],
		color: indigo[900],
		transition: 'background-color 0.3s',
		padding: "4px",
		'&:hover': {
			backgroundColor: indigo[300]
		}
	},
	headerDeleteButton: {
		backgroundColor: red[100],
		color: red[900],
		transition: 'background-color 0.3s',
		padding: "8px",
		marginRight: "8px",
		'&:hover': {
			backgroundColor: red[300]
		},
	},
	addSendButton: {
		backgroundColor: cyan.A200,
		color: cyan.A700,
		transition: 'background-color 0.3s',
		marginLeft: "16px",
		'&:hover': {
			backgroundColor: cyan.A400
		},
	},
	addCloseButton: {
		backgroundColor: cyan[100],
		color: grey[900],
		transition: 'background-color 0.3s',
		padding: "8px",
		marginRight: "8px",
		'&:hover': {
			backgroundColor: grey[300]
		},
	},
	TableContainer: {
		display: "flex",
		height: '100%'
	},
	TableOneContainer: {
		display: "flex",
		flexGrow: 1,
		borderStyle: "solid",
		borderColor: grey[400],
		borderWidth: "0.8px",
		height: '100%',
		maxWidth: '300px',
		alignItems: "flex-start"
	},
	textField: {
		'& .MuiInputBase-input': {
			fontSize: '0.8rem',
			fontWeight: 'normal',
			fontFamily: 'Monospace',
			backgroundColor: 'white',
			padding: "4px 8px",
			borderRadius: "4px"
		}
	}
}));

const Roles = () => {
	const classes = useStyles();
	
	const firstOptions = [
		{
			value: "Usuarios",
			label: "Usuarios"
		},
		{
			value: "Roles",
			label: "Roles"
		}
	]

	const [userSelected, setUserSelected] = useState(null)
	const [roleSelected, setRoleSelected] = useState(null)

	let _userSelected = null
	let _roleSelected = null

	const [firstTitle, setFirstTitle] = useState(firstOptions[0])
	const [secondTitle, setSecondTitle] = useState(null)
	const [thirdTitle, setThirdTitle] = useState(null)

	const [firstData, setFirstData] = useState(null)
	const [secondData, setSecondData] = useState(null)
	const [thirdData, setThirdData] = useState(null)

	const [firstLoading, setFirstLoading] = useState(false)
	const [secondLoading, setSecondLoading] = useState(false)
	const [thirdLoading, setThirdLoading] = useState(false)

	const [firstIcon, setFirstIcon] = useState(null)
	const [secondIcon, setSecondIcon] = useState(null)
	const [thirdIcon, setThirdIcon] = useState(null)

	const [firstDeleteAction, setFirstDeleteAction] = useState(null)
	const [secondDeleteAction, setSecondDeleteAction] = useState(null)
	const [thirdDeleteAction, setThirdDeleteAction] = useState()

	const [firstEditAction, setFirstEditAction] = useState(null)
	const [secondEditAction, setSecondEditAction] = useState(null)
	const [thirdEditAction, setThirdEditAction] = useState(null)

	const [firstSelectAction, setFirstSelectAction] = useState(null)
	const [secondSelectAction, setSecondSelectAction] = useState(null)
	const [thirdSelectAction, setThirdSelectAction] = useState(null)

	const [firstAddAction, setFirstAddAction] = useState(null)
	const [secondAddAction, setSecondAddAction] = useState(null)
	const [thirdAddAction, setThirdAddAction] = useState(null)

	const [firstAddData, setFirstAddData] = useState(null)
	const [secondAddData, setSecondAddData] = useState(null)
	const [thirdAddData, setThirdAddData] = useState(null)

	// Socket bind for role-permission
	useEffect(() => {
		if (roleSelected === null) return;
		const companyId = localStorage.getItem("companyId");
		const socket = socketConnection({ companyId });
		socket.on(`company-${companyId}-role-${roleSelected}`, (data) => {
			if (data.action === "bind") {
				const setPermissionData = firstTitle.value === "Usuarios" ? setThirdData : setSecondData;
				const setPermissionAddData = firstTitle.value === "Usuarios" ? setThirdAddData : setSecondAddData;
				setPermissionData((oldArray) => {
					return [...oldArray, {id: data.record.id, name:data.record.key}]
				});
				setPermissionAddData((oldArray) => {
					return oldArray.filter(element => element.value !== data.record.id)
				});
			}
			if (data.action === "unbind") {
				const setPermissionData = firstTitle.value === "Usuarios" ? setThirdData : setSecondData;
				const setPermissionAddData = firstTitle.value === "Usuarios" ? setThirdAddData : setSecondAddData;
				setPermissionData((oldArray) => {
					return oldArray.filter(element => element.id !== data.record.id)
				});
				setPermissionAddData((oldArray) => {
					return [...oldArray, {value: data.record.id, label: data.record.key}]
				});
			}
		})
		return () => {
			socket.disconnect();
		};
	}, [roleSelected])
	//Socket bind for user-roles
	useEffect(() => {
		if (userSelected === null) return;
		const companyId = localStorage.getItem("companyId");
		const socket = socketConnection({ companyId });
		socket.on(`company-${companyId}-role`,(data) => {
			if (data.action === "update") {
				setSecondData((oldArray) => {
					return oldArray.map(element => {
						if (element.id === data.record.id) {
							return data.record;
						}
						return element;
					})
				});
				setSecondAddData((oldArray) => {
					return oldArray.map(element => {
						if (element.value === data.record.id) {
							return {value: data.record.id, label: data.record.name};
						}
						return element;
					})
				});
			}
		})
		socket.on(`company-${companyId}-user-${userSelected}`, (data) => {
			if (data.action === "bind") {
				setSecondData((oldArray) => {
					return [...oldArray, data.record]
				});
				setSecondAddData((oldArray) => {
					return oldArray.filter(element => element.value !== data.record.id)
				});
			}
			if (data.action === "unbind") {
				setSecondData((oldArray) => {
					return oldArray.filter(element => element.id !== data.record.id)
				});
				setSecondAddData((oldArray) => {
					return [...oldArray, {value: data.record.id, label: data.record.name}]
				});
			}
		})
		return () => {
			socket.disconnect();
		};
	}, [userSelected])

	useEffect(()=>{
		if(firstTitle.value === "Usuarios"){
			api.get("/user-roles/users").then((data) => {
				setRoleSelected(null)
				setUserSelected(null)
				_roleSelected = null
				_userSelected = null

				setFirstData(data.data);
				setFirstIcon((<PersonIcon/>))
				setFirstDeleteAction(null)
				setFirstEditAction(null)
				setFirstSelectAction(()=>handleOnSelectUser)
				setFirstAddAction(null)
				setFirstAddData(null)
				
				setSecondData(null)
				setSecondTitle("Roles")
				setSecondIcon((<PersonIcon/>))	
				setSecondDeleteAction(()=>handleOnUnbindRole)
				setSecondEditAction(()=>handleOnUpdateRole)
				setSecondSelectAction(()=>handleOnSelectRole)
				setSecondAddAction(()=>handleOnBindRole)
				setSecondAddData(null)
				
				setThirdData(null)
				setThirdTitle("Permisos")
				setThirdIcon((<PersonIcon/>))
				setThirdDeleteAction(()=>handleOnUnbindPermission)
				setThirdEditAction(()=>null)
				setThirdSelectAction(null)
				setThirdAddAction(()=>handleOnBindPermission)
				setThirdAddData(null)

				const companyId = localStorage.getItem("companyId");
				const socket = socketConnection({ companyId });
				socket.on(`company-${companyId}-user`, (data) => {
					if (data.action === "create") {
						setFirstData((oldArray) => {
							return [...oldArray, data.user]
						});
					}
					if (data.action === "update") {
						setFirstData((oldArray) => {
							return oldArray.map(element => {
								if (element.id === data.user.id) {
									return data.user
								}
								return element
							})
						});
					}
					if (data.action === "delete") {
						setFirstData((oldArray) => {
							return oldArray.filter(element => element.id !== data.userId)
						});
					}
				})
				return () => {
					socket.disconnect();
				};
			}).catch((err) => {
				toast.error(err.response?.data.message);
			})
		}else if(firstTitle.value === "Roles"){
			api.get("/roles/1").then((data) => {
				setRoleSelected(null)
				setUserSelected(null)
				_roleSelected = null
				_userSelected = null

				setFirstData(data.data);
				setFirstIcon((<PersonIcon/>))
				setFirstDeleteAction(()=>handleOnDeleteRole)
				setFirstEditAction(()=>handleOnUpdateRole)
				setFirstSelectAction(()=>handleOnSelectRole)
				setFirstAddAction(()=>handleOnAddRole)
				setFirstAddData(null)
				
				setSecondData(null)
				setSecondTitle("Permisos")
				setSecondIcon((<PersonIcon/>))	
				setSecondDeleteAction(()=>handleOnUnbindPermission)
				setSecondEditAction(null)
				setSecondSelectAction(null)
				setSecondAddAction(()=>handleOnBindPermission)
				setSecondAddData(null)
				
				setThirdData(null)
				setThirdTitle(null)
				setThirdIcon(null)
				setThirdDeleteAction(null)
				setThirdEditAction(null)
				setThirdSelectAction(null)
				setThirdAddAction(null)
				setThirdAddData(null)

				const companyId = localStorage.getItem("companyId");
				const socket = socketConnection({ companyId });
				socket.on(`company-${companyId}-role`, (data) => {
					if (data.action === "create") {
						setFirstData((oldArray) => {
							return [...oldArray, data.record]
						});
					}
					if (data.action === "update") {
						setFirstData((oldArray) => {
							return oldArray.map(element => {
								if (element.id === data.record.id) {
									return data.record
								}
								return element
							})
						});
					}
					if (data.action === "delete") {
						setFirstData((oldArray) => {
							return oldArray.filter(element => element.id !== data.elementId)
						});
					}
				})
				return () => {
					socket.disconnect();
				};
			}).catch((err) => {
				toast.error(err.response?.data.message);
			})
		}
	},[firstTitle])

	async function handleOnSelectUser(id){
		try{

			setUserSelected(id)
			_userSelected = id
	
			setSecondData(null);
			setSecondAddData([]);
	
			const userrole = await api.get(`/user-roles/user/${id}`);
			const roles = await api.get(`/roles/1`)
			setSecondData(userrole.data?.roles);
			setSecondAddData(
				roles.data
					.filter(element => !userrole.data?.roles.some( role => role.id === element.id ))
					.map((element)=>({value:element.id, label:element.name}))
			)
		}catch(err){
			toast.error(err.response?.data.message);
		}
	}
	async function handleOnSelectRole(id){
		try{
			setRoleSelected(id)
			_roleSelected = id
			const setPermissionData = firstTitle.value === "Usuarios" ? setThirdData : setSecondData;
			const setPermissionAddData = firstTitle.value === "Usuarios" ? setThirdAddData : setSecondAddData;
	
			setPermissionData(null);
			setPermissionAddData([]);
	
			const result = await api.get(`/role-permissions/role/${id}`);
			const permissions = await api.get(`/permissions/1`);
			setPermissionData(result.data?.permissions.map(element=>({id:element.id, name:element.key})));
			setPermissionAddData(
				permissions.data
					.filter(element => !result.data?.permissions.some( permission => permission.id === element.id ))
					.map((element)=>({value:element.id, label:element.key}))
			)
		}catch(err){
			toast.error(err.response?.data.message);
		}
	}

	async function handleOnUpdateRole({id, value}){
		try{
			await api.put(`/roles`,{
				name: value,
				id
			});
		}catch(err){
			toast.error(err.response?.data.message);
		}
	}

	async function handleOnDeleteRole(id){
		try{
			await api.delete(`/roles/${id}`);
		}catch(err){
			toast.error(err.response?.data.message);
		}
	}

	async function handleOnUnbindRole(id){
		try{
			await api.delete(`/user-roles`,{
				data:{
					userId:_userSelected,
					roleId:id
				}
			});
		}catch(err){
			toast.error(err.response?.data.message);
		}
	}
	async function handleOnUnbindPermission(id){
		try{
			await api.delete(`/role-permissions`,{
				data:{
					roleId:_roleSelected,
					permissionId:id
				}
			});
		}catch(err){
			toast.error(err.response?.data.message);
		}
	}

	async function handleOnAddRole(name){
		try{
			await api.post(`/roles/`,{
					name
			});
		}catch(err){
			toast.error(err.response?.data.message);
		}
	}

	async function handleOnBindRole(id){
		try{
			await api.post(`/user-roles`,{
					userId:_userSelected,
					roleId:id
			});
		}catch(err){
			toast.error(err.response?.data.message);
		}
	}
	async function handleOnBindPermission(id){
		try{
			await api.post(`/role-permissions`,{
					permissionId:id,
					roleId:_roleSelected
			});
		}catch(err){
			toast.error(err.response?.data.message);
		}
	}


	return (
		<MainContainer className={classes.mainContainer}>
			<MainHeader>
				<Title>Roles</Title>
			</MainHeader>
			<TableContainer component={Paper} className={classes.TableContainer}>
				<OneColumnTable
					data={firstData}
					title={(
						<Select
							defaultValue={firstOptions[0]}
							options={firstOptions}
							value={firstTitle}
							onChange={(e) => setFirstTitle(e)}
						/>
					)}
					icon={firstIcon}
					onDeleteElement={firstDeleteAction}
					onEditElement={firstEditAction}
					onSelectElement={firstSelectAction}
					onAddElement={firstAddAction}
					addData={firstAddData}
					loading={firstLoading}
				/>
				{secondData && (<OneColumnTable
					data={secondData}
					title={secondTitle}
					icon={secondIcon}
					onDeleteElement={secondDeleteAction}
					onEditElement={secondEditAction}
					onSelectElement={secondSelectAction}
					onAddElement={secondAddAction}
					addData={secondAddData}
					loading={secondLoading}
				 />)}
				{thirdData && (<OneColumnTable
					data={thirdData}
					title={thirdTitle}
					icon={thirdIcon}
					onDeleteElement={thirdDeleteAction}
					onEditElement={thirdEditAction}
					onSelectElement={thirdSelectAction}
					onAddElement={thirdAddAction}
					addData={thirdAddData}
					loading={thirdLoading}
				 />)}
				
			</TableContainer>
		</MainContainer>
	);
};

const OneColumnTable = ({ data, title, icon, onEditElement, onDeleteElement, onSelectElement, onAddElement, addData, loading }) => {
	const classes = useStyles();

	const [disabledActions, setDisabledActions] = useState(true)

	const [selected, setSelected] = useState(null)
	const [edited, setEdited] = useState(false)

	const [editAction, setEditAction] = useState(()=>{})
	const [selectAction, setSelectAction] = useState(()=>{})

	useEffect(() => {
		if ((selected || selected === 0) && onSelectElement) {
			onSelectElement(selected)
		}
	}, [selected])

	async function handleOnSelect(id, actions) {
		if (((selected || selected === 0) ?? null) === id) {
			setDisabledActions(true)
			setSelected(null)
			setEditAction(() => () => { })
			setSelectAction(() => () => { })

			return false
		}
		if (!(selected || selected === 0)) {
			setDisabledActions(false)
			setSelected(id)
			setEditAction(() => actions.toggleEditMode)
			setSelectAction(() => actions.toggleSelectMode)
			return true
		}
		if (!edited) {
			selectAction(false)
			setSelected(id)
			setEditAction(() => actions.toggleEditMode)
			setSelectAction(() => actions.toggleSelectMode)
			return true
		}
		return false
	}

	async function handleStartEdit() {
		const state = !edited
		setEdited(state)
		editAction(state)
	}

	async function handleStartDelete() {
		setDisabledActions(true)
		setSelected(null)
		setEditAction(() => () => { })
		setSelectAction(() => () => { })
		onDeleteElement(selected)
	}
	async function handleSendEdit({id, value}) {
		setDisabledActions(true)
		setSelected(null)
		setEditAction(() => () => { })
		setSelectAction(() => () => { })
		setEdited(false)
		onEditElement({id, value})
	}

	return (
		<Box className={classes.TableOneContainer}>
		<Table size="small" className={classes.table} >
			<TableHead>
				<TableRow>
					<HeaderCell title={title} icon={icon} disableEdit={disabledActions} disableDelete={disabledActions} onDelete={( onDeleteElement ? handleStartDelete : null)} onEdit={(onEditElement ? handleStartEdit : null)} />
				</TableRow>
			</TableHead>
			<TableBody>
				{loading && (<CircularProgress />)}
				{onAddElement && (<AddCell onSend={onAddElement} data={addData} />)}
				{
					data?.map(
						(element) => (<BodyCell id={element.id} key={element.id} onSelect={handleOnSelect} onSend={handleSendEdit} text={element.name} />)
					)
				}
			</TableBody>
		</Table>
		</Box>
	)
}

const BodyCell = ({ id, text, onSelect = async () => false, onSend = async () => { } }) => {
	const classes = useStyles();
	const [selected, setSelected] = useState(false);
	const [edited, setEdited] = useState(false);
	const [value, setValue] = useState(text);

	const toggleEditMode = (state) => {
		setEdited(state)
	}

	const toggleSelectMode = (state) => {
		setSelected(state)
	}

	async function handleOnSelected() {
		if (!edited) {
			setSelected((await onSelect(id, { toggleEditMode, toggleSelectMode })));
		}
	}

	async function handleOnSend() {
		setEdited(false);
		setSelected(false);
		onSend({id, value})
	}

	return (
		<TableRow>
			<TableCell onClick={handleOnSelected} style={(selected ? { backgroundColor: grey[600], color: "white" } : {})} className={classes.bodyCell}>
				{edited ?
					(
						<Box className={classes.headerCell}>
							<TextField className={classes.textField} variant="outlined" size="small" value={value} onChange={(e) => { setValue(e.target.value) }} />
							<IconButton
								className={classes.bodyEditButton}
								onClick={handleOnSend}
							>
								<SendIcon style={{ fontSize: "12px" }} />
							</IconButton>
						</Box>
					) :
					(text)
				}
			</TableCell>
		</TableRow>
	)
}

const AddCell = ({ onSend = async () => { }, data }) => {
	const classes = useStyles();
	const [selected, setSelected] = useState(false);
	const [value, setValue] = useState(null);

	async function handleOnSelected() {
		if(!selected){
			setSelected(true);
		}
	}

	async function handleOnSend() {
		setValue(null)
		setSelected(!selected);
		onSend(value.value)
	}

	return (
		<TableRow>
			<TableCell onClick={handleOnSelected} style={(selected ? { backgroundColor: cyan[400], color: "white" } : {})} className={classes.addCell}>
			{selected ?
					(data ?
						(
							<Box className={classes.headerCell}>
								<Box display="flex" alignItems="center">
								<Select 
									styles={{
										option: (baseStyles, state) => ({
											...baseStyles,
											backgroundColor: cyan[400],
										}),
										control: (baseStyles, state) => ({
											...baseStyles,
											minWidth: "150px",
											backgroundColor: cyan[400],
										}),
									}}
									options={data}
									value={value} 
									onChange={(e) => { setValue(e) }} 
								/>
									<IconButton
										className={classes.addSendButton}
										onClick={handleOnSend}
									>
										<SendIcon style={{ fontSize: "12px" }} />
									</IconButton>
								</Box>	
								<IconButton
									onClick={()=>setSelected(!selected)}
								>
									<CloseIcon style={{ color: "white", fontSize: "12px" }} />
								</IconButton>
							</Box>
						) :
						(
							<Box className={classes.headerCell}>
								<Box display="flex" alignItems="center">
									<TextField className={classes.textField} variant="outlined" size="small" value={value?.value} onChange={(e) => { setValue({value: e.target.value}) }} />
									<IconButton
										className={classes.addSendButton}
										onClick={handleOnSend}
									>
										<SendIcon style={{ fontSize: "12px" }} />
									</IconButton>
								</Box>	
								<IconButton
									onClick={()=>setSelected(!selected)}
								>
									<CloseIcon style={{ color: "white", fontSize: "12px" }} />
								</IconButton>
							</Box>
						)
					) :
					(<Box className={classes.headerCell}><Add style={{ color: cyan.A700, fontSize: "1.2rem" }} /></Box>)
				}
			</TableCell>
		</TableRow>
	)
}

const HeaderCell = ({ title, icon, onEdit, onDelete, disableEdit, disableDelete }) => {
	const classes = useStyles();
	return (
		<TableCell className={classes.tableCell}>
			<Box className={classes.headerCell}>
				<Box display="flex" alignItems="center" >
					<Box display="flex" alignItems="center" padding="12px" >
						{icon}
					</Box>
					{title}
				</Box>
				<Box display="flex" alignItems="center">
					{onEdit && (
						<IconButton
							className={classes.headerEditButton}
							disabled={disableEdit}
							onClick={onEdit}
						>
							<EditIcon fontSize="small" />
						</IconButton>
					)}
					{onDelete && (
						<IconButton
							className={classes.headerDeleteButton}
							disabled={disableDelete}
							onClick={onDelete}
						>
							<DeleteIcon fontSize="small" />
						</IconButton>
					)}
				</Box>
			</Box>
		</TableCell>
	)
}

export default Roles;
