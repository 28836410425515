import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import TicketResponsiveContainer from "../pages/TicketResponsiveContainer";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import AllowedConnections from "../pages/AllowedConnections/";
import Connections from "../pages/Connections/";
import SettingsCustom from "../pages/SettingsCustom/";
import Financeiro from "../pages/Financeiro/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import SyncContacts from "../pages/SyncContacts/";
import Queues from "../pages/Queues/";
import Tags from "../pages/Tags/";
import MessagesAPI from "../pages/MessagesAPI/";
import Helps from "../pages/Helps/";
import Roles from "../pages/Roles/";
import ContactLists from "../pages/ContactLists/";
import ContactListItems from "../pages/ContactListItems/";
// import Companies from "../pages/Companies/";
import QuickMessages from "../pages/QuickMessages/";
import Kanban from "../pages/Kanban";
import { AuthProvider } from "../context/Auth/AuthContext";
import { TicketsContextProvider } from "../context/Tickets/TicketsContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import { APIConnectionsProvider } from "../context/APIConnections/APIConnectionsContext";
import Route from "./Route";
import Schedules from "../pages/Schedules";
import Campaigns from "../pages/Campaigns";
import CampaignsConfig from "../pages/CampaignsConfig";
import CampaignReport from "../pages/CampaignReport";
import Annoucements from "../pages/Annoucements";
import Chat from "../pages/Chat";
import ToDoList from "../pages/ToDoList/";
import Subscription from "../pages/Subscription/";
import Files from "../pages/Files/";
import Prompts from "../pages/Prompts";
import QueueIntegration from "../pages/QueueIntegration";

const Routes = () => {
  const [showCampaigns, setShowCampaigns] = useState(false);

  useEffect(() => {
    const cshow = localStorage.getItem("cshow");
    if (cshow !== undefined) {
      setShowCampaigns(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <TicketsContextProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            {/* <Route exact path="/create-company" component={Companies} /> */}
            <WhatsAppsProvider>
              <APIConnectionsProvider>
                <LoggedInLayout>
                  <Route
                    exact
                    path="/"
                    permissionKey="view_dashboard_page"
                    component={Dashboard}
                    isPrivate
                  />
                  <Route
                    exact
                    permissionKey="view_tickets_page"
                    path="/tickets/:ticketId?"
                    component={TicketResponsiveContainer}
                    isPrivate
                  />
                  <Route
                    exact
                    permissionKey="view_allowed_connections_page"
                    path="/allowed-connections"
                    component={AllowedConnections}
                    isPrivate
                  />

                  <Route
                    exact
                    permissionKey="view_roles_page"
                    path="/roles"
                    component={Roles}
                    isPrivate
                  />

                  <Route
                    exact
                    permissionKey="view_connections_page"
                    path="/connections"
                    component={Connections}
                    isPrivate
                  />

                  <Route
                    exact
                    permissionKey="view_quick_messages_page"
                    path="/quick-messages"
                    component={QuickMessages}
                    isPrivate
                  />

                  <Route
                    exact
                    permissionKey="view_todolist_page"
                    path="/todolist"
                    component={ToDoList}
                    isPrivate
                  />

                  <Route
                    exact
                    permissionKey="view_schedules_page"
                    path="/schedules"
                    component={Schedules}
                    isPrivate
                  />

                  <Route
                    exact
                    permissionKey="view_tags_page"
                    path="/tags"
                    component={Tags}
                    isPrivate
                  />

                  <Route
                    exact
                    permissionKey="view_contacts_page"
                    path="/contacts"
                    component={Contacts}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_sync_contacts_page"
                    path="/sync-contacts"
                    component={SyncContacts}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_helps_page"
                    path="/helps"
                    component={Helps}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_users_page"
                    path="/users"
                    component={Users}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_files_page"
                    path="/files"
                    component={Files}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_prompts_page"
                    path="/prompts"
                    component={Prompts}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_queue_integration_page"
                    path="/queue-integration"
                    component={QueueIntegration}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_messages_api_page"
                    path="/messages-api"
                    component={MessagesAPI}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_settings_page"
                    path="/settings"
                    component={SettingsCustom}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_kanban_page"
                    path="/kanban"
                    component={Kanban}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_financeiro_page"
                    path="/financeiro"
                    component={Financeiro}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_queues_page"
                    path="/queues"
                    component={Queues}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_announcements_page"
                    path="/announcements"
                    component={Annoucements}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionkey="view_subscription_page"
                    path="/subscription"
                    component={Subscription}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_chats_page"
                    path="/chats/:id?"
                    component={Chat}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_contact_lists_page"
                    path="/contact-lists"
                    component={ContactLists}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_contact_lists_page"
                    path="/contact-lists/:contactListId/contacts"
                    component={ContactListItems}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_campaigns_page"
                    path="/campaigns"
                    component={Campaigns}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_campaigns_page"
                    path="/campaign/:campaignId/report"
                    component={CampaignReport}
                    isPrivate
                  />


                  <Route
                    exact
                    permissionKey="view_campaigns_page"
                    path="/campaigns-config"
                    component={CampaignsConfig}
                    isPrivate
                  />

                </LoggedInLayout>
              </APIConnectionsProvider>
            </WhatsAppsProvider>
          </Switch>
          <ToastContainer autoClose={3000} />
        </TicketsContextProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
