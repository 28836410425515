import { Box, Chip, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

export function StatusFilter({ onFiltered }) {
  const [statusSelected, setStatusSelected] = useState("open");
  const status = [
        { id: 'open', name: "Asistiendo" },
        { id: 'pending', name: "Esperando" },
        { id: 'closed', name: "Resueltos" }
      ];

  const onChange = async (value) => {
    onFiltered(value.target.value);
  };

  return (
    <FormControl size="small" variant="outlined" style={{ padding: "0px 10px 10px" }}>
      <InputLabel style={{ padding: "0px 10px 10px" }} id="demo-simple-select-label">Filtro por estado</InputLabel>
      <Select
        size="small"
        value={statusSelected}
        onChange={(e) => {setStatusSelected(e.target.value);onChange(e)}}
        label="Filtro por estado"
        labelId="demo-simple-select-label"
      >
        {status.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
